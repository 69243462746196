import type SVG from 'svg.js';

import { EASING_FUNCTIONS, THREAD_WEIGHT_MULTIPLIER } from './constants';
import {
  type CurrentPath,
  type CurrentStyle,
  type RGBColor,
  type ThreadInner,
} from './types';
import { normalizeWeight } from './helpers';

/**
 * Displays current segment and manages its graphical components and animations.
 * Single current segment is a line defined by two {@link Cell}s of a breadboard grid with
 * custom properties.
 *
 * Each {@link Current} has its own container to display the animation. The main element is
 * the _line_ along which little particles move to show the weight and direction of the current.
 *
 * (!) Before launching the animation, it's required to call {@link draw}, which is needed to set
 * the animation path.
 *
 * @param container container for the {@link Current} elements and animations
 * @param style     SVG style for the lines
 * @param speed     speed of partical animations
 *
 * @category Breadboard
 */
export class Current {
  /** General properties of the {@link Current} */
  public readonly _thread: ThreadInner;

  /** flags for extra purposes */
  public ___touched?: boolean;

  /** Root container of the {@link Current} */
  private readonly _container: SVG.Container;
  /** Animation-specific container of the {@link Current} */
  private readonly _container_anim: SVG.Nested;
  /** Debug container of the {@link Current} */
  private readonly _group_debug: any;
  /** Current identifier */
  private readonly _id: number;
  /** Schematic style flag */
  private readonly _schematic: boolean;
  /** Current particle SVG elements */
  private _particles: any[];
  /** Current SVG line */
  private _line: SVG.Path;
  /** Geometric SVG-formatted path string of the current line */
  private _line_path: string;
  /** Calculated length of the {@link _line_path} */
  private _line_length: any;
  /** Current animation stylesheet */
  private _sheet: any;
  /** Current animation duration */
  private _anim_dur: any;
  /** Current animation delay */
  private _anim_delay: any;
  /** Current weight */
  private _weight: number;
  /** Current style */
  private _style: {
    linecap: string;
    color: string;
    width: number;
    particle_radius: number;
    opacity: any;
  };

  /** Current visibility flag */
  private _visible: boolean;
  /** Current activation flag */
  private _activated: boolean;
  /** Current burning flag */
  private _burning: boolean;

  private readonly _callbacks: {
    mouseenter: CallableFunction;
    mouseleave: CallableFunction;
  };

  /**
   * Color gradations of the {@link Current} weight
   *
   * An arbitrary number of the colors is allowed.
   * Current instance will pick the color from the gradient of the evenly distributed points of colors listed here
   * based on the normailzed value of the weight (0..1).
   * In this way, currents with weight `0` will have a color `{@link Colors}[0]`,
   * and currents with weight `1` will be colored with `{@link Colors}[Colors.length - 1]`.
   *
   * Each color is a string with a six-digit hexadecimal number preceded by hash symbol (`#`).
   */
  static Colors: string[] = [
    '#006eff',
    '#ff0006',
    // "#cd1800",
    // "#f65200",
    // "#ff8602",
    // "#ced601",
    // "#01c231",
    // "#00c282",
    // "#00b5c2",
    // "#006ec2",
  ];

  /** Length of the animation loop for currents with minimum {@link weight} */
  static DurationMin: number = 200;
  /** Length of the animation loop for currents with maximum {@link weight} */
  static DurationMax: number = 10000;
  /** Distance between adjacent particles (px) */
  static AnimationDelta: number = 200;

  /** Line width for currents with maximum weight (px) */
  static WidthMax = 14;
  /** Line width for currents with maximum weight (px) - schematic mode */
  static WidthSchematicMax = 10;

  /** Particle radius for currents with maximum weight (px) */
  static RadiusMax = 18;
  /** Particle radius for currents with maximum weight (px) - schematic mode */
  static RadiusSchematicMax = 16;
  private _rule_idx_burn_kfs: any;
  private _rule_idx_burn: any;

  /** Weight point when the current begins to lose its opacity (the weight is lower - the current is less opaque) */
  static get FullOpacityThreshold() {
    return 0.07;
  }

  constructor(
    container: SVG.Container,
    thread: ThreadInner,
    schematic: boolean,
  ) {
    this._thread = thread;
    this._container = container.nested();
    this._container_anim = this._container.nested(); // animation root

    this._group_debug = undefined; // this.container.group();      // debug root

    this._id = Math.floor(Math.random() * 10 ** 6); // Default identifier is a random six-digit number

    // Misc internal parameters
    this._schematic = schematic;
    this._particles = [];

    // Animation parameters
    this._sheet = undefined;
    this._anim_dur = undefined;
    this._anim_delay = undefined;

    // Misc animation parameters
    this._weight = normalizeWeight(thread.weight);
    this._style = this._getStyle(this._weight);

    this._visible = false;
    this._activated = false;
    this._burning = false;

    this._callbacks = {
      mouseenter: () => {},
      mouseleave: () => {},
    };
  }

  /**
   * Identifier of the {@link Current}
   */
  get id(): number {
    return this._id;
  }

  get weight(): number {
    return this._weight;
  }

  get weight_thread(): number {
    return this._thread.weight;
  }

  /**
   * Retuns undefined if connected to a junction
   */
  get thread() {
    const { src, dst, ...rest } = this._thread;
    if (typeof src !== 'object' || typeof dst !== 'object') {
      return undefined;
    }

    return { src, dst, ...rest };
  }

  /**
   * Whether the current is short-circuited
   */
  get is_burning(): boolean {
    return this._thread.weight * THREAD_WEIGHT_MULTIPLIER > 2;
  }

  /**
   * Renders current line by given path
   *
   * The glow filter can be applied.
   *
   * @param path original SVG path (geometric coordinates)
   */
  public draw(path: CurrentPath | string): void {
    this._line_path =
      typeof path === 'string' ? path : Current._pathArrayToString(path);
    this._line_length = Current.getPathLength(this._line_path);

    this._line = this._container.path(this._line_path);

    this._line.fill('none').stroke(this._style);

    this._container_anim.before(this._line);
    this._container_anim.opacity(0);

    if (this._group_debug) {
      this._group_debug.move(
        this._line.x() + Current.WidthMax,
        this._line.y() + Current.WidthMax,
      );
    }

    this._addGlowFilter();

    this._attachEventHandlers();

    this._visible = true;
  }

  /**
   * Erases the current
   */
  public erase(): void {
    if (!this._line) {
      console.warn('An attempt to erase NULL line was made');
      return;
    }

    this._detachEventHandlers();

    this._particles = [];

    this._line.remove();
    this._container.remove();
    this._container_anim.remove();

    this._sheet.ownerNode.remove();

    if (this._group_debug) {
      this._group_debug.remove();
    }

    this._visible = false;
    this._activated = false;
  }

  /**
   * Checks whether the given thread is identical with the {@link Current}'s one
   *
   * @param {Object} thread thread to compare
   */
  public hasSameThread(thread: ThreadInner): boolean {
    if (!this._thread) {
      return false;
    }

    if (
      typeof thread.src === 'number' &&
      typeof this._thread.src === 'number'
    ) {
      if (thread.src !== this._thread.src) {
        return false;
      }
    } else if (
      typeof thread.src === 'object' &&
      typeof this._thread.src === 'object'
    ) {
      if (
        thread.src.x !== this._thread.src.x ||
        thread.src.y !== this._thread.src.y
      ) {
        return false;
      }
    } else {
      return false;
    }

    if (
      typeof thread.dst === 'number' &&
      typeof this._thread.dst === 'number'
    ) {
      if (thread.dst !== this._thread.dst) {
        return false;
      }
    } else if (
      typeof thread.dst === 'object' &&
      typeof this._thread.dst === 'object'
    ) {
      if (
        thread.dst.x !== this._thread.dst.x ||
        thread.dst.y !== this._thread.dst.y
      ) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  /**
   * Animates the current within the {@link _line} path
   *
   * A certain number of particles are generated. Particle is a vector object representing the
   * current motion along its path. Along with the color, it helps to visually "feel" the current weight.
   *
   * Each particle cycles through the path fragment of length `delta` at a certain `speed`
   * in a way that the motion path of the next particle begins at the end of the previous
   * particle motion path.
   *
   * It's important to reduce lags as much as possible in order to update animation properties,
   * so instead of re-drawing particles, which causes lags due to CPU-intensive DOM component mount operations,
   * this method alters animation properties through CSS manipulations.
   * To make speed changes seamless, particle animation delay is calculated from the very beginning
   * of the first {@link Current} appearance as if the new speed was always the same.
   * See {@link _setParticleSpeed} to get more details.
   *
   * @see _animateParticle
   * @see _setParticleSpeed
   *
   * @param weight current particle motion speed
   */
  public activate(): void {
    if (!this._visible) {
      throw new Error('Cannot activate invisible current');
    }
    if (this._activated) {
      return;
    }

    if (!this._sheet) {
      this.deactivate();
    }

    // длительность прохода Current.AnimationDelta px пути
    const dur = Math.ceil(
      Current.DurationMax +
        this._weight * (Current.DurationMin - Current.DurationMax),
    );

    // Число частиц на ток
    const particles_per_line = this._line_length / Current.AnimationDelta;

    // Для каждой частицы:
    for (let i = 0; i < particles_per_line; i++) {
      // Вычислить начальную и конечную позиции движения по контуру (в процентах от всей длины контура)
      const progress_start = (i * Current.AnimationDelta) / this._line_length;
      let progress_end = ((i + 1) * Current.AnimationDelta) / this._line_length;

      // Если частица - последняя, то progress_end будет больше 1
      // Необходимо скорректировать конечную позицию для последней частицы,
      // так как длина всего пути может быть не кратной количеству частиц
      if (progress_end > 1) {
        progress_end = 1;
      }

      // Сгенерировать частицу
      this._particles[i] = this._container_anim.circle(
        this._style.particle_radius * 2,
      );

      // Заливка и центрирование
      this._particles[i].fill({
        color: Current.pickColorFromRange(this._weight),
        opacity: Current.pickOpacityFromRange(this._weight),
      });

      // Анимировать частицу
      this._animateParticle(
        this._particles[i],
        i,
        particles_per_line,
        progress_start,
        progress_end,
        dur,
      );
    }

    this._updateDebugInfo();

    // Это необходимо для того, чтобы дать браузеру вставить анимацию во все полигоны
    // В противном случае, на малую долю секунды будет заметна частица в положении (0,0)
    // до начала анимации
    setTimeout(() => {
      // функция _updateBurning включает/выключает видимость контейнера с частицами
      this._updateBurning();

      // функция _updateBurning делает это не всегда, поэтому нужно в случае необходимости отображения частиц
      // дополнительно убедится в их видимости
      if (!this.is_burning) {
        this._container_anim.opacity(1);
      }

      this._activated = true;
    }, 0);
  }

  /**
   * Stops the {@link Current} animation
   */
  public deactivate(): void {
    this._particles = [];
    this._container_anim.clear();
    this._initStyleSheet();
    this._activated = false;
  }

  /**
   * Shows hidden particles
   *
   * @see hideParticles
   */
  public showParticles(): void {
    this._container_anim.opacity(1);
  }

  /**
   * Hides visible particles
   *
   * @see showParticles
   */
  public hideParticles(): void {
    this._container_anim.opacity(0);
  }

  /**
   * Alters the {@link Current} weight
   *
   * @param weight
   */
  public setWeight(weight: number = 0) {
    const _weight = normalizeWeight(weight);

    if (this._thread.weight !== weight) {
      // задать скорость
      this._setParticleSpeed(_weight);

      // изменить цвет в стиле контура
      this._style = this._getStyle(_weight);

      // применить стиль к контуру
      this._line?.stroke(this._style);

      // изменить цвет у всех частиц
      for (const particle of this._particles) {
        particle.fill({
          color: this._style.color,
          opacity: this._style.opacity,
        });
      }
    }

    this._weight = _weight;
    this._thread.weight = weight;

    this._updateBurning();
    this._updateDebugInfo();
  }

  public makeHoverable(hoverable: boolean): void {
    this._container.style({ cursor: hoverable ? 'pointer' : 'default' });
  }

  /**
   * Attaches a mouse enter event
   *
   * @param cb callback function to be called when the cursor entered the current
   */
  public onMouseEnter(cb: CallableFunction): void {
    if (!cb) {
      cb = () => {};
    }

    this._callbacks.mouseenter = cb;
  }

  /**
   * Attaches a mouse leave event
   *
   * @param cb callback function to be called when the cursor left the current
   */
  public onMouseLeave(cb: CallableFunction): void {
    if (!cb) {
      cb = () => {};
    }

    this._callbacks.mouseleave = cb;
  }

  private _attachEventHandlers() {
    this._container.on('mouseenter', () => this._callbacks.mouseenter());
    this._container.on('mouseleave', () => this._callbacks.mouseleave());
  }

  private _detachEventHandlers() {
    this._container.off('mouseenter');
    this._container.off('mouseleave');
  }

  /**
   * Enables or disables short circuit effect
   *
   * This function should not be called manually because the animation loop
   * is sensitive to specific effects such as this.
   *
   * Each time the weight is changed, {@link setWeight} calls this function to
   * ensure proper state of the animation to display short circuit effect.
   */
  private _updateBurning() {
    if (this.is_burning) {
      // "Сжигать" ток от КЗ
      this._burnEnable();
    } else {
      // Отключить "сжигание", возможно, включённое ранее
      this._burnDisable();
    }
  }

  /**
   * Apply glow effect to the current line
   *
   * @deprecated
   */
  private _addGlowFilter(): void {
    // this._line.attr('filter', 'url(#glow-current)');
  }

  /**
   * Enable short circuit effect
   *
   * @see setWeight
   * @see _updateBurning
   * @see _burnDisable
   */
  private _burnEnable(): void {
    if (this._burning) {
      return;
    }
    this._burning = true;

    if (!this._line_path) {
      throw new Error("Cannot animate current which hasn't been drawn");
    }

    this.hideParticles();

    const animname = `cur-${this._id}-burn`;

    this._rule_idx_burn_kfs = this._sheet.insertRule(
      this._generateKeyframeRuleBurn(this._id, 1000),
    );

    this._rule_idx_burn = this._sheet.insertRule(`.${animname} {
            stroke-dasharray: 100;
            
            animation: dash-${this._id} 600ms linear reverse infinite;
        }`);

    this._line.node.classList.add(animname);
  }

  /**
   * Disable short circuit effect
   *
   * @see setWeight
   * @see _updateBurning
   * @see _burnEnable
   */
  private _burnDisable(): void {
    if (!this._burning) {
      return;
    }
    this._burning = false;

    this._sheet.deleteRule(this._rule_idx_burn_kfs);
    this._sheet.deleteRule(this._rule_idx_burn);

    this._line.node.classList.remove(`cur-${this._id}-burn`);

    this.showParticles();
  }

  /**
   * Animate individual particle
   *
   * For animation, CSS Keyframes is used.
   * This approach is most optimal for real-time animation parameter updates,
   * but it requires more memory to keep the CSS and more code to manipulate it.
   *
   * It's supposed to call this function in the loop over the particles.
   *
   * @param particle          particle's SVG element
   * @param index             particle index in the current
   * @param particles_count   number of the particles in the current
   * @param progress_start    the percentage of the path to begin the motion
   * @param progress_end      the percentage of the path to end the motion
   * @param dur               time for a particle to move {@link AnimationDelta} px
   */
  private _animateParticle(
    particle: SVG.Circle,
    index: number,
    particles_count: number,
    progress_start: number,
    progress_end: number,
    dur: number = 1000,
  ) {
    if (!this._line_path) {
      throw new Error("Cannot animate current which hasn't been drawn");
    }

    // actual difference between progress point
    const progress_diff_actual = progress_end - progress_start;

    // normalized progress point difference, it's usually equal to `progress_diff_actual`
    // except for the last particle, which has a slightly shorter (incomplete) path.
    const progress_diff_normal = 1 / particles_count;

    // Rule prefix within the CSS animation class
    const animname = `cur-${this._id}-${index}-anim`;

    // Animation finish percent (use ceil if floating point value will cause troubles)
    const perc = (progress_diff_actual / progress_diff_normal) * 100;

    let rule_animation, // CSS animation class
      rule_keyframes_move, // CSS keyframes for the motion
      rule_keyframes_blink, // CSS keyframes for the opacity
      rule_keyframes_radius; // CSS keyframes for the radius

    /// Define the keyframes

    // for motion
    rule_keyframes_move = this._generateKeyframeRuleMove(
      index,
      progress_start * 100,
      progress_end * 100,
      perc,
    );

    // for opacity - for particles passing an incomplete path (i.e. the last one)
    if (perc < 100) {
      rule_keyframes_blink = this._generateKeyframeRuleBlink(index, perc);
    }

    // for scaling
    if (progress_start === 0 && progress_end === 1) {
      // scaling down - for the first and the last particles simultaneously
      rule_keyframes_radius = this._generateKeyframeRuleScaleUpDown(
        index,
        perc,
      );
    } else if (progress_start === 0) {
      // scaling up - for the first particle only
      rule_keyframes_radius = this._generateKeyframeRuleScaleUp(index, perc);
    } else if (progress_end === 1) {
      // scaling down - for the last particle only
      rule_keyframes_radius = this._generateKeyframeRuleScaleDown(index, perc);
    }

    /// Construct animation class properties

    // motion
    rule_animation = `.${animname} {animation:  ${this._generateAnimationRuleMove(
      index,
      dur,
    )}`;
    this._sheet.insertRule(rule_keyframes_move);

    // opacity
    if (rule_keyframes_blink) {
      rule_animation += `, ${this._generateAnimationRuleBlink(index, dur)}`;
      this._sheet.insertRule(rule_keyframes_blink);
    }

    // radius
    if (rule_keyframes_radius) {
      rule_animation += `, ${this._generateAnimationRuleScale(index, dur)}`;
      this._sheet.insertRule(rule_keyframes_radius);
    }

    rule_animation += `; offset-path: path('${this._line_path}')`;
    rule_animation += `; transform: translate(-${this._style.particle_radius}px, -${this._style.particle_radius}px)`;

    rule_animation += ';}';

    this._sheet.insertRule(rule_animation);

    particle.node.classList.add(animname);

    /// Apply time-related properties
    this._anim_dur = dur;
    this._anim_delay = 0;
  }

  private _generateKeyframeRuleBurn(id: number, to: number) {
    return `
            @keyframes dash-${this._id} {
                to {
                    stroke-dashoffset: ${to}; 
                }
            }
        `;
  }

  private _generateKeyframeRuleMove(
    index: number,
    from: number,
    to: number,
    perc: number,
  ) {
    return `
            @keyframes cur-${this._id}-${index}-kfs-move {
                0% {offset-distance: ${from}%}
                ${perc}% {offset-distance: ${to}%}
                100% {offset-distance: ${to}%}
            } 
        `;
  }

  private _generateKeyframeRuleBlink(index: number, perc: number) {
    return `
            @keyframes cur-${this._id}-${index}-kfs-blink {
                0% {opacity: 1}
                ${perc}% {opacity: 1}
                100% {opacity: 0}
            }
        `;
  }

  private _generateKeyframeRuleScaleUp(index: number, perc: number) {
    const scale_min = this._style.width / 2;
    const scale_max = this._style.particle_radius;

    return `
            @keyframes cur-${this._id}-${index}-kfs-radius {
                0% {r: ${scale_min}}
                ${perc * 0.4}% {r: ${scale_max}}
                100% {r: ${scale_max}}
                }
        `;
  }

  private _generateKeyframeRuleScaleDown(index: number, perc: number) {
    const scale_min = this._style.width / 2;
    const scale_max = this._style.particle_radius;

    return `
            @keyframes cur-${this._id}-${index}-kfs-radius {
                0% {r: ${scale_max}}
                ${perc * 0.6}% {r: ${scale_max}}
                ${perc}% {r: ${scale_min}}
                100% {r: ${scale_min}}
            }
        `;
  }

  private _generateKeyframeRuleScaleUpDown(index: number, perc: number) {
    const scale_min = this._style.width / 2;
    const scale_max = this._style.particle_radius;

    return `
            @keyframes cur-${this._id}-${index}-kfs-radius {
                0% {r: 10}
                ${perc * 0.4}% {r: ${scale_max}}
                ${perc * 0.6}% {r: ${scale_max}}
                ${perc}% {r: ${scale_min}}
                100% {r: ${scale_min}}
            }
        `;
  }

  private _generateAnimationRuleSmoke(
    duration: number,
    nonlinearity: number = 0,
  ) {
    if (nonlinearity < 0) {
      throw RangeError("animation nonlinearity shouldn't be less than 0");
    }
    if (nonlinearity > 1) {
      throw RangeError("animation nonlinearity shouldn't be more than 1");
    }

    const secnum = 50 * nonlinearity;
    const firstnum = 100 - secnum;

    return `smokemove ${duration}ms cubic-bezier(${firstnum / 100},${
      secnum / 100
    },1,1) reverse;`;
  }

  private _generateAnimationRuleMove(index: number, duration: number) {
    return `cur-${this._id}-${index}-kfs-move ${duration}ms linear infinite`;
  }

  private _generateAnimationRuleBlink(index: number, duration: number) {
    return `cur-${this._id}-${index}-kfs-blink ${duration}ms step-start infinite`;
  }

  private _generateAnimationRuleScale(index: number, duration: number) {
    return `cur-${this._id}-${index}-kfs-radius ${duration}ms linear infinite`;
  }

  private _setParticleSpeed(speed: number) {
    if (!this._sheet) {
      return;
    }

    let delay;

    // новая длительность цикла анимации (ДЦА), мс
    const dur =
      Current.DurationMax + speed * (Current.DurationMin - Current.DurationMax);

    // время, прошедшее с начала запуска анимации
    // let dt = new Date().getTime() - this._anim_timestamp;
    const dt =
      (this._container_anim.node as unknown as SVGSVGElement).getCurrentTime() *
      1000;

    // сколько прошло времени для того, чтобы частица попала в текущее положение
    const togo_now = (dt - this._anim_delay) % this._anim_dur; // при текщущей ДЦА, учитывая предыдущую задержку
    const togo_willbe = dt % dur; // при новой ДЦА

    // процент положения частицы на пути
    const pct_now = togo_now / this._anim_dur; // при текущей ДЦА
    const pct_willbe = togo_willbe / dur; // при новой ДЦА

    // разница в положении частицы при разных ДЦА:
    // положительная, если происходит ускорение
    // отрицательная, если происходит замедление
    const pct_diff = pct_willbe - pct_now;

    // отрицательная задержка - анимация будет "перематываться" вперёд
    // при новой ДЦА на ту же точку, на которой она была при старой ДЦА
    delay = -(dur - pct_diff * dur);

    for (const rule of this._getSheetRules()) {
      if (rule.constructor.name === 'CSSStyleRule') {
        rule.style.animationDuration = `${dur}ms, ${dur}ms`;
        rule.style.animationDelay = `${delay}ms`;
      }
    }
    this._anim_dur = dur;
    this._anim_delay = delay;
  }

  private _getSheetRules() {
    // firefox compat
    return this._sheet.rules ? this._sheet.rules : this._sheet.cssRules;
  }

  private _initStyleSheet() {
    if (this._sheet) {
      this._sheet.ownerNode.remove();
    }

    const style = document.createElement('style');
    style.id = String(this._id);
    document.body.appendChild(style);

    this._sheet = style.sheet;

    this._initStaticAnimationRules();
  }

  private _initStaticAnimationRules() {
    this._sheet.insertRule(`@keyframes smokemove {
            0% {
                opacity: 0;
            }
            10% {
                 opacity: 0;
            }
            100% {
                opacity: 1; 
                offset-distance: 100%;
            }
        }`);
  }

  static getPathLength(path: string) {
    const path_node = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path',
    );
    path_node.innerHTML = path;
    path_node.setAttributeNS(null, 'd', path);

    return path_node.getTotalLength();
  }

  static _pathArrayToString(path_arr: CurrentPath): string {
    let str = '';

    for (const path_item of path_arr) {
      switch (path_item.length) {
        case 3: {
          str += `${path_item[0]} ${path_item[1]},${path_item[2]} `;
          break;
        }
        default:
          throw new Error('Invalid path array');
      }
    }

    return str;
  }

  _getStyle(weight: number): CurrentStyle {
    const width_max = this._schematic
      ? Current.WidthSchematicMax
      : Current.WidthMax;
    const radii_max = this._schematic
      ? Current.RadiusSchematicMax
      : Current.RadiusMax;

    // const alpha = weight / 0.1;
    const alpha = 1;

    const width = weight > 0.1 ? width_max : Math.floor(alpha * width_max);
    const radii = weight > 0.1 ? radii_max : Math.floor(alpha * radii_max);

    const style = {
      linecap: 'round',
      color: Current.pickColorFromRange(weight),
      width,
      particle_radius: radii,
      opacity: Current.pickOpacityFromRange(weight),
    };

    return style;
  }

  _updateDebugInfo(): void {
    const wght_anim = Number.parseFloat(String(this._weight)).toPrecision(4);
    const wght_thrd = Number.parseFloat(
      String(this._thread.weight),
    ).toPrecision(4);

    if (this._group_debug) {
      this._group_debug.clear();
      this._group_debug
        .text(`aw:  ${wght_anim}\ntw:  ${wght_thrd}`)
        .font({ 'line-height': '1em', weight: 'bold' })
        .style({ color: 'blue' });
    }
  }

  static pickOpacityFromRange(weight: number): number {
    weight = weight > 1 ? 1 : weight < 0 ? 0 : weight;

    const max = Current.FullOpacityThreshold;

    weight = weight > max ? 1 : 1 - Math.exp((-10 * weight) / max);

    return weight;
  }

  static pickColorFromRange(weight: number): string {
    // вес должен быть в пределах [0..1]
    weight = weight > 1 ? 1 : weight < 0 ? 0 : weight;

    // размер секции перехода цветов (secsize <= 1)
    const secsize = 1 / (Current.Colors.length - 1);

    // номер секции перехода цветов (section <= кол-во цветов)
    let section = Math.ceil(weight / secsize);
    section = section > 0 ? section - 1 : 0;

    // вес в рамках секции (0 <= subweight <= 1)
    const subweight = weight - secsize * section;

    let color_min, color_max;

    switch (Current.Colors.length) {
      case 0: {
        color_min = color_max = '#000000';
        break;
      }
      case 1: {
        color_min = color_max = Current.Colors[0];
        break;
      }
      default: {
        if (section === Current.Colors.length - 1) {
          color_min = Current.Colors[section - 1];
          color_max = Current.Colors[section];
        } else {
          color_min = Current.Colors[section];
          color_max = Current.Colors[section + 1];
        }
      }
    }

    color_min = this.convertHexToRGB(color_min);
    color_max = this.convertHexToRGB(color_max);

    // FIXME: Color jumping

    const rgb = this.pickHex(color_max, color_min, subweight);

    return this.convertRGBToHex(rgb);
  }

  static convertHexToRGB(hex: string): RGBColor {
    return [
      parseInt(hex.slice(1, 3), 16),
      parseInt(hex.slice(3, 5), 16),
      parseInt(hex.slice(5, 7), 16),
    ];
  }

  static convertRGBToHex(rgb: RGBColor): string {
    let rs = Number(rgb[0]).toString(16);
    let gs = Number(rgb[1]).toString(16);
    let bs = Number(rgb[2]).toString(16);

    if (rs.length === 1) {
      rs = '0' + rs;
    }
    if (gs.length === 1) {
      gs = '0' + gs;
    }
    if (bs.length === 1) {
      bs = '0' + bs;
    }

    return '#' + rs + gs + bs;
  }

  static pickHex(color1: RGBColor, color2: RGBColor, weight: number): RGBColor {
    const w1 = weight;
    const w2 = 1 - w1;

    return [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ];
  }
}
