import * as React from 'react';
import { type AllProps, type IViewProps, View } from '~/js/core/base/view/View';

import ProcessMath from '../../utils/mathjax/ProcessMath';

import '~/css/blocks/richtext.less';

namespace RichTextView {
  export interface Props extends IViewProps {
    content: string;
  }

  export class RichTextView extends View<Props> {
    constructor(props: AllProps<Props>) {
      super(props);
    }

    render(): React.ReactNode {
      return (
        <ProcessMath>
          <div
            className="richtext"
            dangerouslySetInnerHTML={{ __html: this.props.content }}
            style={{ padding: '1em' }}
          />
        </ProcessMath>
      );
    }
  }
}

export default RichTextView;
