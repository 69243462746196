import { ModelEvent } from '~/js/core/base/Event';
import type { SerializedPlate } from '~/js/utils/breadboard/core/Plate';
import type { ElecData } from '~/js/utils/breadboard/core/Current';
import { type CoreMode } from '~/js/models/constants';
import { type DeviceStatus } from '~/js/models/common/ConnectionModel/types';

export class UserPlateEvent extends ModelEvent<PlateEvent> {
  plates: SerializedPlate[];
}

export class PlateEvent extends ModelEvent<PlateEvent> {
  plates: SerializedPlate[];
}

export class FieldsEvent extends ModelEvent<FieldsEvent> {
  fields: Record<number, any>;
}

export class ElectronicEvent extends ModelEvent<ElectronicEvent> {
  elec_data: ElecData;
}

export class BoardErrorEvent extends ModelEvent<BoardErrorEvent> {
  code: number;
  message: string;
}

export class BoardModeEvent extends ModelEvent<BoardModeEvent> {
  is_demo: boolean;
  mode: CoreMode;
}

export class BoardLayoutEvent extends ModelEvent<BoardLayoutEvent> {
  layout_name: string;
}

export class BoardStatusEvent extends ModelEvent<BoardStatusEvent> {
  status: DeviceStatus;
  version?: string;
  port?: string;
}

export class BoardTimeoutEvent extends ModelEvent<BoardTimeoutEvent> {}

export class BoardAnalogResetEvent extends ModelEvent<BoardAnalogResetEvent> {
  arduino_pins: [number, string | number][];
}

export class BoardUnknownEvent extends ModelEvent<BoardUnknownEvent> {
  version: string;
}

export class ForceLayoutEvent extends ModelEvent<ForceLayoutEvent> {
  layout_name: string;
  force_layout_name?: string;
}
